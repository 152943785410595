import React from "react"

import { FormattedMessage } from 'react-intl'

import Layout from "../components/layout"
import SEO from "../components/seo"

// Images
import viewProduct from "../../static/images/views/product-view.png";
import viewCart from "../../static/images/views/cart-view.png";
import viewHome from "../../static/images/views/home-view.png";
import shapeBg from "../../static/images/shape1.png";

import CartImage from "../../static/images/feature-cart.png"
import PersonalImage from "../../static/images/feature-personal.png"
import SalesImage from "../../static/images/feature-sales.png"
import SegmentImage from "../../static/images/feature-segment.png"
import SimilarImage from "../../static/images/similar-items.png";
import BigCorpImage from "../../static/images/feature-big.png";

import LocalizedLink from '../components/localizedLink';

const IndexPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale}>
        <SEO title="Reko.ai - verkkokaupan tuotesuosittelun tekoäly"/>
        <div className={"page-header home"}>
            <h1><FormattedMessage id="PersonalizedExperience" /></h1>
            <p><FormattedMessage id="HeroCopy" /></p>
            <div className="views">
                <div className="Img">
                    <img alt={"Home View: Personalized homepage"} src={viewHome}/>
                    <h2 className="personal"><FormattedMessage id="RekoPersonal" /></h2>
                    <h3><FormattedMessage id="ViewPersonalised" /></h3>
                </div>
                <div className="Img">
                    <img alt={"Product View: Similar products, See also"} src={viewProduct}/>
                    <h2 className="similar"><FormattedMessage id="RekoSimilar" /></h2>
                    <h3><FormattedMessage id="ViewSimilarSeeAlso" /></h3>
                </div>
                <div className="Img">
                    <img alt={"Cart View: Similar products, See also"} src={viewCart}/>
                    <h2 className="session"><FormattedMessage id="RekoSession" /></h2>
                    <h3><FormattedMessage id="ViewCrosssale" /></h3>
                </div>
            </div>
            <a href="#features"><FormattedMessage id="ReadmoreFeatures" /></a>
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Compete"} src={BigCorpImage}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2><FormattedMessage id="GiantTech" /></h2>
                                <p><FormattedMessage id="Feature11" /></p><br />
                                <p><FormattedMessage id="Feature12" /></p><br/>
                                <p><FormattedMessage id="Feature13" /></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2><FormattedMessage id="Feature2Title" /></h2>
                                <p><FormattedMessage id="Feature21" /></p><br />
                                <p><FormattedMessage id="Feature22" /></p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Recommender Systems"} src={SalesImage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"targets"}>
                <div className={"title"} id={"features"}>
                    <h2><FormattedMessage id="Features" /></h2>
                </div>
                <div className={"row"}>
                    <div className={"col-4"}>
                        <div className={"item"}>
                            <blockquote><FormattedMessage id="ForYou" /></blockquote>
                            <img alt={"Personalised Recommendations"} src={PersonalImage}/>
                            <h3 className="personal"><FormattedMessage id="RekoPersonal" /></h3>
                            <p><FormattedMessage id="RekoPersonalDescription" /></p>
                        </div>
                    </div>
                    <div className={"col-4"}>
                        <div className={"item"}>
                            <blockquote><FormattedMessage id="CollaborativeFiltering" /></blockquote>
                            <img alt={"Cart Upsell"} src={CartImage}/>
                            <h3 className="session"><FormattedMessage id="RekoSession" /></h3>
                            <p><FormattedMessage id="RekoSessionDescription" /></p>
                        </div>
                    </div>
                    <div className={"col-4"}>
                        <div className={"item"}>
                            <blockquote><FormattedMessage id="SimilarProductsToThis" /></blockquote>
                            <img alt={"Similar Items"} src={SimilarImage}/>
                            <h3 className="similar"><FormattedMessage id="RekoSimilar" /></h3>
                            <p><FormattedMessage id="RekoSimilarDescription" /></p>
                        </div>
                    </div>
                </div>
                {/* <div className={"row"}>
                    <div className={"col-6"}>
                        <div className={"item"}>
                            <blockquote><FormattedMessage id="InStoreMarketing" /></blockquote>
                            <img alt={"Segments"} src={SegmentImage}/>
                            <h3 className="segments"><FormattedMessage id="RekoSegments" /></h3>
                            <p><FormattedMessage id="RekoSegmentsDescription" /></p>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>

        {/* <div className={"blog-link"}>
            <div className={"container"}>
            <LocalizedLink to="/blog/22-01-2020/suosittelujarjestelmat" title={"Suosittelujärjestelmät"}>
                <FormattedMessage id="Readmore" />
            </LocalizedLink>
            </div>
        </div> */}

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2><FormattedMessage id="CallToActionTitle" /></h2>
                    <p><FormattedMessage id="CallToActionBody" /></p>
                    <br />
                    <p style={{'font-size': '13px'}}><FormattedMessage id="PricingInfo" /><b><FormattedMessage id="NoFee" /></b></p>
                </div>

                <div className={"button"}>
                    <LocalizedLink to="/signup" title={"Reko.ai"}>
                        <FormattedMessage id="CallToActionButton" />
                    </LocalizedLink>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
